import React from 'react';
// import Typography from '@mui/material/Typography';
import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';

import OurMission from './OurMission';
import OurVision from './OurVision';
import Founder from './Founder';

export default function AboutPage() {
  return (
    <>
      <Seo title='About' />
      <DefaultLayout>
        <Page pageTitle='About'>
          <OurMission />
          <OurVision />
          <Founder />

          {/* <Typography variant='h3' align='center' gutterBottom={true}>
            Coming Soon
          </Typography> */}
        </Page>
      </DefaultLayout>
    </>
  );
}
