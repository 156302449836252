import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import aboutTranslations from '../../../../locales-js/en/about';

const OurMission = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: '2rem 0rem 2rem 0rem',
        // background:
        //   'transparent linear-gradient(229deg, #98c44f, #97c450 9%, #53b894 44%, #0facd8 89%, #2160af) 0% 0% no-repeat padding-box',
      }}
    >
      <Grid
        container
        spacing={1}
        // align='center'
        // justify='center'
        // direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ border: '0px solid red' }}
        mt={0}
        mb={5}
        pt={10}
        pb={10}
      >
        <Grid item xs={12} md={4} sx={{ border: '0px solid green' }}>
          <Typography
            align='center'
            variant='h3'
            p={5}
            sx={{ fontWeight: 'bold' }}
          >
            {aboutTranslations.ourMission}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} sx={{ border: '0px solid blue' }}>
          <Typography variant='h4' p={2}>
            {aboutTranslations.ourMissionDescription1}
          </Typography>
          {/* <Typography variant='h6' p={2}>
            {aboutTranslations.ourMissionDescription2}
          </Typography> */}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OurMission;
