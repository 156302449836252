import * as React from 'react';

import AboutPage from '../../ui/pages/AboutPage';

// markup
const Page = () => {
  return <AboutPage />;
};

export default Page;
