import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { StaticImage } from 'gatsby-plugin-image';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import ScienceIcon from '@mui/icons-material/Science';

import aboutTranslations from '../../../../locales-js/en/about';
import Link from '../../../components/Link';

const Founder = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: '2rem 0rem 2rem 0rem',
        // background:
        //   'transparent linear-gradient(229deg, #98c44f, #97c450 9%, #53b894 44%, #0facd8 89%, #2160af) 0% 0% no-repeat padding-box',
      }}
    >
      <Grid
        container
        spacing={1}
        // align='center'
        // justify='center'
        // direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ border: '0px solid red' }}
        mt={0}
        mb={5}
        pt={0}
        pb={10}
      >
        <Grid
          item
          xs={12}
          sx={{ border: '0px solid green', textAlign: 'center' }}
        >
          <Typography
            align='center'
            variant='h3'
            sx={{ fontWeight: 'bold' }}
            p={3}
          >
            {aboutTranslations.founder}
          </Typography>
          <StaticImage
            src='../../../../assets/images/founder/christos_tsakostas.jpg'
            style={{ borderRadius: '50%', border: `3px solid #515253` }}
            alt='Christos Tsakostas'
            width={250}
          />
          <Typography align='center' variant='h4' pt={2}>
            {aboutTranslations.founderName}
          </Typography>
          <Stack spacing={2} p={2} direction='row' justifyContent='center'>
            <Link to='https://www.linkedin.com/in/tsakostas/'>
              <StaticImage
                src='../../../../assets/images/social-media/317725_linkedin_social_icon.png'
                style={{ borderRadius: '0%', border: `0px solid #515253` }}
                alt='Christos Tsakostas Linked In Profile'
                width={30}
              />
            </Link>
            <Link to='https://www.researchgate.net/profile/Christos-Tsakostas/'>
              <StaticImage
                src='../../../../assets/images/social-media/1200px-ResearchGate_icon_SVG.svg.png'
                style={{ borderRadius: '0%', border: `0px solid #515253` }}
                alt='Christos Tsakostas Research Gate Profile'
                width={30}
              />
            </Link>
            <Link to='https://medium.com/@tsakostas'>
              <StaticImage
                src='../../../../assets/images/social-media/1200px-Medium_logo_Monogram.svg.png'
                style={{ borderRadius: '0%', border: `0px solid #515253` }}
                alt='Christos Tsakostas Medium Profile'
                width={30}
              />
            </Link>
          </Stack>
          <Divider />
        </Grid>

        <Grid item>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ border: '0px solid green' }}>
              <Typography
                align='justify'
                variant='h5'
                p={2}
                sx={{ fontWeight: 'bold' }}
              >
                {aboutTranslations.founderTitle1}
              </Typography>
              <Typography align='justify' variant='body1' p={2}>
                {aboutTranslations.founderDescription1}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ border: '0px solid blue' }}>
              <Typography
                align='justify'
                variant='h5'
                p={2}
                sx={{ fontWeight: 'bold' }}
              >
                {aboutTranslations.founderTitle2}
              </Typography>
              <Typography align='justify' variant='body1' p={2}>
                {aboutTranslations.founderDescription2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Founder;
