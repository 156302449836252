const aboutTranslations = {
  ourMission: 'Our Mission',
  ourMissionDescription1:
    'To offer a superior sound experience by «magically» processing the audio of your web browser.',
  ourMissionDescription2:
    'To offer a superior listening experience by «magically» processing the sound of your web browser.',
  ourVision: 'Our Vision',
  ourVisionDescription1:
    "To offer the one-and-only web extension/addon for audio you'll ever need.",
  ourVisionDescription2:
    'To offer a superior listening experience by «magically» processing the sound of your web browser.',
  founder: 'Founder',
  founderName: 'Christos Tsakostas',
  founderTitle1: 'Software Architect/BA/Engineer',
  founderDescription1:
    'With an Electrical & Computing Engineering background, my greatest passions are research and the development of useful solutions. I have participated in numerous software projects in the last two decades for a variety of industries, such as FinTech, Banks, European Organisations, Western Union, etc. as a programmer, analyst, designer, technical writer, architect, and team leader. I am focused on Domain-Driven Design, Event-Driven Architectures, and Microservices mainly with Java & React.',
  founderTitle2: 'Independent Researcher in 3D Audio',
  founderDescription2:
    'Back in 1998, I was a Ph.D. candidate in the Institut für Kommunikationsakustik RUB, Germany, under the guidance of Professor Jens Blauert and Professor John Mourjopoulos. My principal work was to perform virtual psychoacoustic experiments with human listeners and investigate the perception of reflections in enclosed spaces (the Precedence Effect). After 2001, I worked as an independent researcher in the fields of 3D Sound over speakers and headphones, published related articles, and presented parts of my work at acoustics conferences. My first creation was called AMPHIOTIK, later on, I created ARIA-3D, Polyfono, and now Magic Audio.',
};

export default aboutTranslations;
